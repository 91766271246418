import { canAccessLocalStorage } from "../../javascripts/utils";
import { firstTrackWithSrc } from './helpers/text_tracks';

export default function setupCaptionsSaving(
  player,
  { localStorageKey = "bbcm-captions" } = {}
) {
  if (!canAccessLocalStorage()) return;

  const tracks = player.textTracks();
  const captionButtons = document.querySelectorAll(
    "button.vjs-subs-caps-button"
  );

  tracks.addEventListener("change", () => {
    for (let i = 0; i < tracks.length; i++) {
      const track = tracks[i];
      if (track.kind === "captions" && track.label !== "") {
        const state = track.mode == "showing" ? "true" : "false";
        captionButtons.forEach((button) => {
          button.setAttribute("aria-pressed", state);
        });
        localStorage.setItem(localStorageKey, track.mode);
      }
    }
  });

  const initialMode = localStorage.getItem(localStorageKey);
  // Use player.ready to restore the track after VideoJS
  // picks a preferred track (unfortunately, no sign of an API
  // to access this `userPref` referenced in their code)
  // https://github.com/videojs/video.js/blob/affc0611d978b1a6d02e443a46d14a58f2b1441c/src/js/tracks/text-track-display.js#L130
  player.ready(() => {
    restoreTextTrackMode();
  });

  function restoreTextTrackMode() {
    const track = firstTrackWithSrc(player);
    if (track && initialMode) {
      track.mode = initialMode;
    }
  }
}
