import { transitionElement } from './helpers/transition_element';

const CAPTIONS_BUTTON_SELECTOR = ".vjs-subs-caps-button button";

export function setupShowreelPlayer(player) {
  showCaptionsByDefault(player);
  autoplayWhenInView(player);
  pauseWhenHidden(player);
  setupUnmuteTransition(player);
}

export function setupAutoplayAfterFirstPlay(player) {
  player.one("play", () => {
    autoplayWhenInView(player);
  });

  // This adds "Play Trailer" text to the bigPlayButton
  //
  // Note: this uses aria-hidden="true" so that it isn't read out by screenreaders.
  // We already have an appropriate label for this button therefore this only
  // needs to be presentational
  player.bigPlayButton
    .el()
    .insertAdjacentHTML(
      "beforeend",
      "<span aria-hidden='true' class='vjs-play-trailer-text'>Play</span"
    );
}

function showCaptionsByDefault(player) {
  const tracks = player.textTracks();

  player.ready(function () {
    const videoCaptionsButton = player
      .el()
      .querySelector(CAPTIONS_BUTTON_SELECTOR);

    for (let i = 0; i < tracks.length; i++) {
      const track = tracks[i];
      if (track.kind === "captions") {
        track.mode = "showing";
        // Set the captions button to have its 'active, pressed' state by default
        videoCaptionsButton.setAttribute("aria-pressed", "true");
      }
    }
  });
}

/* eslint-disable promise/catch-or-return */
function autoplayWhenInView(player) {
  const autoplayObserver = new IntersectionObserver((entries) => {
    entries.forEach(function (entry) {
      if (entry.isIntersecting) {
        if (!player.el().dataset.preventAutoPlay) player.play();
      } else {
        player.pause();
      }
    });
  });
  autoplayObserver.observe(player.el());

  player.on("ended", () => autoplayObserver.unobserve(player.el()));
}

export default function pauseWhenHidden(player) {
  const playerObserver = new ResizeObserver((entries) => {
    entries.forEach((entry) => {
      if (entry.contentRect.width == 0) {
        player.pause();
        if (player.isFullscreen()) player.exitFullscreen();
      }
    });
  });
  playerObserver.observe(player.el());
}

function setupUnmuteTransition(player) {
  const playerEvents = ['volumechange', 'click', 'ended'];
  const transitionEvent = transitionToUnmuted.bind(null, player, playerEvents);

  playerEvents.forEach(playerEvent => {
    player.one(playerEvent, transitionEvent);
  });
}

async function transitionToUnmuted(player, playerEvents) {
  playerEvents.forEach(playerEvent => {
    player.off(playerEvent, event);
  });

  // Clicking/tapping anywhere over the player pauses the video, which
  // cannot be prevented using `preventDefault()`.
  // Immediately resuming the video here until a better fix can be found
  if (event.type === 'click') {
    player.play();
    player.muted(false);
  }

  await transitionElement(
    player.el(),
    'remove',
    'vjs-autoplay-showreel--volume-left-side'
  );
  await transitionElement(
    player.el(),
    'remove',
    'vjs-autoplay-showreel--hide-elements'
  );
}
