import { canAccessLocalStorage } from "../../javascripts/utils";
// A parser to handle the HTML returned by the server
const parser = new DOMParser();

export async function playNextLesson(player, url) {
  // Safari has a bug where changing the lesson can result in the video continue to play even though there is only one video element
  // on the page. The video continues even after removing the entire DOM tree in dev tools.
  // To avoid this issue we'll navigate away to the next page instead of loading the video in place with the logic below.
  if (
    /Safari/i.test(navigator.userAgent) &&
    /Apple Computer/.test(navigator.vendor) &&
    !/Mobi|Android/i.test(navigator.userAgent)
  ) {
    window.location.href = url;
  }

  // Show the spinner while making a possibly long remote
  // request
  player.loadingSpinner.show();
  player.loadingSpinner.el().style.visibility = 'visible';

  // Make that we're playing the next lesson to prevent
  // flash of the "Big play button" as sources get swapped
  player.setAttribute('data-playing-next-lesson', '');

  try {
    // Query the new page's HTML to prepare the replacement
    const html = await $.get({
      url,
      headers: {
        Accept: 'text/html'
      }
    });
    updateHTML(html, player);

    // Last, push the new URL in the history to allow proper navigation
    window.Turbolinks.controller.pushHistoryWithLocationAndRestorationIdentifier(
      url,
      window.Turbolinks.uuid()
    );
  } catch (error) {
    // If anything bad happens default back to Turbolinks
    window.Turbolinks.visit(url);
  } finally {
    // Whatever happens, hide the loader to clean things up properly
    player.loadingSpinner.hide();
    player.loadingSpinner.el().style.visibility = '';
  }
}

function updateHTML(html, player) {
  const dom = parser.parseFromString(html, 'text/html');

  updateDocumentHead(dom);
  updateLessonInfo(dom);
  updateLessonVideo(dom, player);
  updateScripts(dom);
}

function updateDocumentHead(newDocument) {
  // Use TurboLink's classes to update the tags inside the `<head>`
  // more easily and reliably. Skipping the styles and scripts updates, though,
  // as we're remaining on the same page so they don't budge
  // https://github.com/turbolinks/turbolinks/blob/71b7a7d0546a573735af99113b622180e8a813c2/src/snapshot_renderer.ts#L51
  const currentHeadDetails = new window.Turbolinks.HeadDetails([
    ...document.head.children
  ]);
  for (const element of currentHeadDetails.getProvisionalElements()) {
    document.head.removeChild(element);
  }
  const newHeadDetails = new window.Turbolinks.HeadDetails([
    ...newDocument.head.children
  ]);
  for (const element of newHeadDetails.getProvisionalElements())
    [document.head.appendChild(element)];
}

function updateScripts(newDocument) {
  const existingScripts = [...document.body.querySelectorAll('script')];
  for (const script of existingScripts) {
    script.parentElement.removeChild(script);
  }

  const scripts = newDocument.body.querySelectorAll('script');
  for (const script of scripts) {
    // `<script>` tags don't get automatically executed when moved from
    // one document to another, so we need to re-create `<script>` tags
    // from this document
    const activeScriptElement = window.Turbolinks.SnapshotRenderer.prototype.createScriptElement(
      script
    );
    document.body.appendChild(activeScriptElement);
  }
}

const SELECTORS_LESSON_INFO = [
  '.js-lesson-info__overview',
  '.js-lesson-info__details',
  '.js-lesson-info__send-lesson'
];

function updateLessonInfo(newDocument) {
  SELECTORS_LESSON_INFO.forEach(selector => {
    const current = document.querySelector(selector);
    const replacement = newDocument.querySelector(selector);

    if (current && replacement) {
      replaceElement(current, replacement);
    }
  });
}

function replaceElement(current, replacement) {
  current.parentElement.replaceChild(replacement, current);
}

function updateLessonVideo(newDocument, player) {
  const newPlayerEl = newDocument.querySelector('.js-video');
  updatePlayerSource(newPlayerEl, player);
  updatePlayerTracks(newPlayerEl, player);
  updatePlayerPoster(newPlayerEl, player);
  updatePlayerAttributes(newPlayerEl, player);
  updateVideoTitle(newPlayerEl, player);
  // Trigger an event to let other components react
  // to the change of lesson, whatever triggered it
  player.trigger('lessonLoaded');

  // Restart play and remove the overlay
  // as soon as the new video starts playing
  // Need to backtrack to 0 and explicitely play
  // to ensure native player in Safari starts back OK
  player.play();
  setTimeout(() => {
    player.currentTime(0);
  }, 10);
}

function updatePlayerTracks(newPlayerEl, currentPlayer) {
  // First remove existing player tracks
  const existingTracks = currentPlayer.remoteTextTracks();
  while (existingTracks.length) {
    existingTracks[0].mode = 'disabled';
    currentPlayer.removeRemoteTextTrack(existingTracks[0]);
  }

  // And add the new ones
  const tracks = newPlayerEl.querySelectorAll('track');
  [...tracks].forEach(
    track =>
      console.log('Adding new tracks') ||
      // We can't directly add the HTML elements there
      // we need to create plain objects copying the attributes
      currentPlayer.addRemoteTextTrack({
        src: track.src,
        kind: track.kind,
        label: track.label,
        srclang: track.srclang,
        default: track.hasAttribute('default'),
        mode:
          (canAccessLocalStorage() && localStorage["bbcm-captions"]) ||
          "disabled",
      })
  );
}

function updatePlayerSource(newPlayerEl, currentPlayer) {
  const source = newPlayerEl.querySelector('source');
  currentPlayer.src(source);
}

const BLACK_GIF =
  'data:image/gif;base64,R0lGODlhAQABAIAAAAUEBAAAACwAAAAAAQABAAACAkQBADs=';

function updatePlayerPoster(newPlayerEl, currentPlayer) {
  currentPlayer.poster(BLACK_GIF);
  currentPlayer.el().poster = BLACK_GIF;

  // Give a little hand to Safari that would still show the old poster
  const video = currentPlayer.el().querySelector('video');
  currentPlayer.el().style.backgroundImage = `url(${BLACK_GIF})`;
  video.style.backgroundImage = `url(${BLACK_GIF})`;
}

const PLAYER_ATTRIBUTES = [
  'data-course-id',
  'data-lesson-id',
  'data-lesson-number',
  'data-lesson-title',
  'data-track-duration',
  'data-load-at-time',
  'data-completed-percentage',
  'data-maestro',
  'data-next-lesson-title',
  'data-next-lesson-path'
];

const AUTOPLAY_LIKE_BUTTON = ".vjs-autoplay__like-button";

function updatePlayerAttributes(newPlayerEl, currentPlayer) {
  // Reset the state of the like button in the transition screen based on if
  // the user has liked the lesson about to be played
  const button = document.querySelector(AUTOPLAY_LIKE_BUTTON);
  const likedStatus = currentPlayer.dataset.likedStatus;

  button.dataset.status = likedStatus;

  const currentPlayerEl = currentPlayer.el();
  const currentPlayerVideoEl = currentPlayerEl.querySelector('video');
  PLAYER_ATTRIBUTES.forEach(attributeName => {
    if (newPlayerEl.hasAttribute(attributeName)) {
      const attributeValue = newPlayerEl.getAttribute(attributeName);
      currentPlayer.setAttribute(attributeName, attributeValue);
      currentPlayerVideoEl.setAttribute(attributeName, attributeValue);
    } else {
      currentPlayer.removeAttribute(attributeName);
      currentPlayerVideoEl.removeAttribute(attributeName);
    }
  });
}

// TODO: Create a component to handle the video title
// and use the `lessonLoaded` event to refresh its values
// for better encapsulation
function updateVideoTitle(newPlayerEl, currentPlayer) {
  ['lesson-title', 'lesson-number', 'maestro'].forEach(attributeName => {
    const element = currentPlayer
      .el()
      .querySelector(`.vjs-lesson-title__${attributeName}`);
    if (element) {
      // Element might be missing on narrow viewports
      element.textContent = newPlayerEl.getAttribute(`data-${attributeName}`);
    }
  });
}
